<template>
  <div class="UsLicenses-container">
    <div class="title-content">
      <img src="./../assets/image/licenses-icon.png" class="title-logo" @click="goHome" />
    </div>
    <div class="title-us">
      <div class="us-text">US Licenses</div>
    </div>
    <div class="list-box">
      <div class="list-title">Alchemy Pay Inc is licensed in the following U.S. jurisdictions.</div>
      <div class="item-list">
        <div class="item-row">
          <div>Jurisdiction</div>
          <div>License Number</div>
          <div>Regulatory Agency</div>
        </div>

        <div class="item-row">
          <div>Arizona</div>
          <div>2449570</div>
          <div>Department of Insurance and Financial Institutions</div>
        </div>

        <div class="item-row">
          <div>Arkansas</div>
          <div>131437</div>
          <div>Arkansas Securities Department</div>
        </div>

        <div class="item-row">
          <div>Lowa</div>
          <div>2023-0136</div>
          <div>State of Lowa Division of Banking</div>
        </div>
        <div class="item-row">
          <div>Minnesota</div>
          <div>MN-MT-2449570</div>
          <div>Minnesota Department of Commerce, Financial Institutions Division</div>
        </div>

        <div class="item-row">
          <div>New Hampshire</div>
          <div>27020-MT</div>
          <div>New Hampshire Banking Department</div>
        </div>
        <div class="item-row">
          <div>New Mexico</div>
          <div>None</div>
          <div>New Mexico Financial Institutions Division</div>
        </div>
        <div class="item-row">
          <div>Oklahoma</div>
          <div>None</div>
          <div>Oklahoma Banking Department</div>
        </div>
        <div class="item-row">
          <div>Oregon</div>
          <div>None</div>
          <div>Oregon Division of Financial Regulation</div>
        </div>
        <div class="item-row">
          <div class="Iowa">Wyoming</div>
          <div class="Iowa">7360</div>
          <div class="Iowa">Department of Audit</div>
        </div>
      </div>
    </div>
    <div class="state-content">
      <div class="state-title">State Disclosures:</div>
      <div class="state-text-box">
        <div class="text-title">Arkansas</div>
        <div>
          If you have a complaint, please contact Alchemy Pay Inc at +1(866)584-9768 or
          <a class="link-style" href="mailto:Contact@allins.io" target="_blank">info@alchemypay.org.</a>
        </div>
        <div>You may also contact Arkansas Securities Department if your complaints or disputes regarding Alchemy Pay Inc money transmission services remains</div>
        <div>
          unsolved, you may file a complaint with the Arkansas Security Department by clicking
          <a class="link-style" href="https://securities.arkansas.gov/file-a-complaint-2/" target="_blank">here.</a>
          You may also fill out the
          <a class="link-style" href="https://securities.arkansas.gov/wp-content/uploads/2022/07/ComplaintForm-July-2022.pdf" target="_blank">complaint form</a>
          and email to
        </div>
        <div>ASDInfo@securities.arkansas.gov or mail to the Arkansas Security Department at:</div>
        <div class="bottom-text">
          <div>1 COMMERCE WAY, SUITE 402</div>
          <div>LITTLE ROCK, ARKANSAS 72202</div>
          <div>TELEPHONE: (501) 324-9260</div>
          <div>FACSIMILE: (501) 324-9268</div>
        </div>
      </div>
    </div>
    <div class="provides">
      <div class="learn-more">
        <div class="logo">
          <img class="logo" src="@/assets/image/logo_icon.png" alt="" />
          <div class="incorporated">Incorporated on October 22, 2022 as a C-Corporation in the State of California, Alchemy Pay, Inc. (NMLS ID: 2449570) is a payment gateway provider that seamlessly connects crypto and global fiat currencies for businesses, developers, and users.</div>
        </div>
        <div class="other">
          <div class="products">
            <ul>
              <li class="title">Products</li>
              <li class="link-name" @click="goHome">On-Ramp</li>
              <li class="link-name" @click="goHome">Off-Ramp</li>
            </ul>
          </div>
          <div class="products contact-us">
            <ul>
              <li class="title">Contact us</li>
              <li>
                <a class="email" href="mailto:info@alchemypay.org">info@alchemypay.org</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="canvas-bg">
        <canvas class="isLoaded" id="gradient-canvas1"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Gradient from './../tool/Gradient.js';
export default {
  data() {
    return {
      gradient: null // 渐变背景类名
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    // 初始化动态背景
    initGradient(id) {
      this.gradient = new Gradient();
      this.gradient.initGradient(id);
    }
  },
  mounted() {
    this.initGradient('#gradient-canvas1');
  },
  destroyed() {
    this.gradient.pause();
  },
  created() {}
};
</script>
<style lang="scss" scoped>
#gradient-canvas {
  --gradient-color-1: #1cbaf7;
  --gradient-color-2: #1cbaf7;
  --gradient-color-3: #1509f9;
  --gradient-color-4: #961aee;
  opacity: 0;
  // filter: blur(1px);
  // -webkit-filter: blur(1px);
}
#gradient-canvas.isLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-in 50ms;
}
#gradient-canvas1 {
  --gradient-color-1: #1cbaf7;
  --gradient-color-2: #1cbaf7;
  --gradient-color-3: #1509f9;
  --gradient-color-4: #961aee;
  opacity: 0;
}
#gradient-canvas1.isLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-in 50ms;
  // pointer-events: none;
}
@media screen and (max-width: 1180px) {
  .title-content {
    height: 100px;
    padding: 31px 0 35px 20px;
    box-sizing: border-box;
    background: #fff;
  }
  .title-logo {
    width: 149px;
    height: 24px;
    object-fit: cover;
    cursor: pointer;
  }
  .title-us {
    width: 100%;
    height: 100px;
    color: #fff;
    text-align: center;
    background: #000;
  }
  .us-text {
    font-family: 'Bold';
    font-size: 24px;
    line-height: normal;
    padding-top: 30px;
  }
  .list-box {
    margin: 36px auto 0 auto;
  }
  .list-title {
    font-family: 'Bold';
    font-size: 20px;
    color: #000;
    margin-left: 16px;
    margin-right: 16px;
  }
  .item-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 16px 0 16px;
  }
  .item-row {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Medium';
    font-size: 11px;
    color: #525868;
    border-top: 1px solid var(--1, #ccd2e2);
  }
  .Iowa {
    border-bottom: 1px solid var(--1, #ccd2e2);
  }
  .item-row div:nth-child(1) {
    width: 33.33%;
    height: 48px;
    line-height: 48px;
    border-left: 1px solid var(--1, #ccd2e2);
    border-right: 1px solid var(--1, #ccd2e2);
    text-align: center;
  }
  .item-row div:nth-child(2) {
    width: 33.33%;
    height: 48px;
    line-height: 48px;
    border-right: 1px solid var(--1, #ccd2e2);
    text-align: center;
  }
  .item-row div:nth-child(3) {
    width: 33.33%;
    height: 48px;
    border-right: 1px solid var(--1, #ccd2e2);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .state-content {
    margin: 36px 16px 0 16px;
    padding-bottom: 36px;
  }
  .state-title {
    font-family: 'Bold';
    font-size: 24px;
    color: #000;
    margin-bottom: 34px;
  }
  .state-text-box {
    font-size: 16px;
    color: #525868;
    font-family: 'Medium';
    line-height: 40px;
  }
  .text-title {
    margin-bottom: 35px;
  }
  .bottom-text {
    margin-top: 34px;
  }
  .link-style {
    color: #0b48e6;
  }
  .link-style:hover {
    text-decoration: none;
    border-bottom: 1.5px solid blue;
  }
  .provides {
    position: relative;
    height: 300px;
    overflow: hidden;
    .canvas-bg {
      width: 100%;
      position: absolute;
      top: 0;
      // left: 0;
      z-index: 0;
      canvas {
        width: 100%;
        height: auto;
      }
    }
    .learn-more {
      position: relative;
      z-index: 1;
      font-style: normal;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      .logo {
        img {
          width: auto;
          height: 24px;
          margin-bottom: 24px;
          margin-left: 16px;
        }
        .incorporated {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;
          color: #ffffff;
          margin-left: 16px;
          margin-right: 16px;
        }
        .us-Licenses {
          margin-top: 30px;
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .other {
        display: flex;
        margin-top: 20px;
        margin-left: 16px;
        .products {
          ul {
            li {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #ffffff;
              margin-bottom: 10px;
              cursor: pointer;
            }
            li.title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #ffffff;
              cursor: auto;
            }
          }
        }
        .contact-us {
          margin-left: 130px;
          ul {
            li {
              a {
                color: #3dfff3;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1180px) and (max-width: 10000px) {
  .title-content {
    height: 100px;
    padding: 31px 0 35px 77px;
    box-sizing: border-box;
    background: #fff;
  }
  .title-logo {
    width: 211px;
    height: 34px;
    object-fit: cover;
    cursor: pointer;
  }
  .title-us {
    width: 100%;
    height: 200px;
    color: #fff;
    text-align: center;
    background: #000;
  }
  .us-text {
    font-family: 'Bold';
    font-size: 60px;
    line-height: normal;
    padding-top: 59px;
  }
  .list-box {
    width: 1180px;
    margin: 80px auto 0 auto;
  }
  .list-title {
    font-family: 'Bold';
    font-size: 24px;
    color: #000;
  }
  .item-list {
    border: 1px solid var(--1, #ccd2e2);
    border-bottom: none;
    margin-top: 40px;
  }
  .item-row {
    display: flex;
    align-items: center;
    font-family: 'Medium';
    font-size: 16px;
    color: #525868;
  }
  .item-row div:nth-child(1),
  .item-row div:nth-child(2) {
    width: 260px;
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid var(--1, #ccd2e2);
    border-right: 1px solid var(--1, #ccd2e2);
    text-align: center;
  }
  .item-row div:nth-child(2) {
  }
  .item-row div:nth-child(3) {
    // width: 393px;
    flex: 1;
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid var(--1, #ccd2e2);
    text-align: center;
  }
  .state-content {
    width: 1180px;
    margin: 60px auto 0 auto;
    padding-bottom: 80px;
  }
  .state-title {
    font-family: 'Bold';
    font-size: 24px;
    color: #000;
    margin-bottom: 34px;
  }
  .state-text-box {
    font-size: 16px;
    color: #525868;
    font-family: 'Medium';
    line-height: 40px;
  }
  .text-title {
    margin-bottom: 35px;
  }
  .bottom-text {
    margin-top: 34px;
  }
  .link-style {
    color: #0b48e6;
  }
  .link-style:hover {
    text-decoration: none;
    border-bottom: 1.5px solid blue;
  }
  .provides {
    position: relative;
    height: 300px;
    overflow: hidden;
    .canvas-bg {
      width: 100%;
      position: absolute;
      top: 0;
      // left: 0;
      z-index: 0;
      canvas {
        width: 100%;
        height: auto;
      }
    }
    .learn-more {
      position: relative;
      z-index: 1;

      font-style: normal;
      color: #ffffff;
      max-width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      // justify-content: space-between;
      .logo {
        img {
          width: auto;
          height: 33px;
          margin-bottom: 24px;
        }
        .incorporated {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          max-width: 656px;
        }
        .us-Licenses {
          margin-top: 30px;
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .other {
        display: flex;
        margin-left: 145px;
        .products {
          ul {
            li {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff;
              margin-bottom: 24px;
              cursor: pointer;
            }
            li.title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 32px;
              cursor: auto;
            }
          }
        }
        .link-name:hover {
          text-decoration: none;
          text-decoration: underline;
        }
        .contact-us {
          margin-left: 130px;
          ul {
            li {
              a {
                color: #3dfff3;
              }
              a:hover {
                text-decoration: none;
                border-bottom: 1px solid #3dfff3;
              }
            }
          }
        }
      }
    }
  }
}
</style>
